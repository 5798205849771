import { computed } from "vue"

/**
 * Map of all unique file types that are accepted by the application.
 */
const AcceptTypes = {
  '.csv': 'text/csv',
  '.txt': 'text/plain',
  '.png': 'image/png',
  '.gif': 'image/gif',
  '.jpeg': 'image/jpeg',
  '.jpg': 'image/jpeg',
  '.bmp': 'image/bmp',
  '.ico': 'image/x-icon',
  '.pdf': 'application/pdf',
  '.svg': 'image/svg+xml',
  '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.ttf': 'application/x-font-ttf',
  '.woff': 'application/font-woff',
  '.otf': 'application/x-font-opentype',
  '.woff2': 'application/font-woff2',
  '.avvoka': '.avvoka'
} as const

/**
 * Map of wildcards for different accepted file types
 */
const AcceptWildcards = {
  'image': [
    '.png', '.jpg', '.jpeg'
  ],
  'favicon': [
    '.png', '.jpg', '.jpeg', '.ico', '.svg'
  ],
  'font': [
    '.ttf', '.woff', '.otf', '.woff2'
  ]
} as const

function isWildcard (value: AcceptValue): value is keyof typeof AcceptWildcards {
  return value in AcceptWildcards
}

type AcceptValue = keyof typeof AcceptTypes | keyof typeof AcceptWildcards | 'all'

export type Accept = AcceptValue | AcceptValue[]

function isAcceptType (value: keyof typeof AcceptTypes | '*'): value is keyof typeof AcceptTypes {
  return value in AcceptTypes
}

export function resolveAccept (accept: Accept | undefined) {
  if (accept) {
    if (Array.isArray(accept)) {
      return resolveWildcards(accept)
    } else {
      return resolveWildcards([accept])
    }
  } else {
    return []
  }
}

export function resolveWildcards (accept: AcceptValue[]) {
  return accept.flatMap((value) => {
    if (isWildcard(value)) {
      return AcceptWildcards[value]
    } else if (value === 'all') {
      return '*'
    } else {
      return value
    }
  })
}

export function toContentTypes (values: (keyof typeof AcceptTypes | '*')[]) {
  if (values.every(isAcceptType)) {
    return values.map((value) => AcceptTypes[value])
  } else {
    return []
  }
}

export function useAccept (props: { accept: Accept | undefined }) {
  const resolvedAcceptValues = computed(() => resolveAccept(props.accept))

  const acceptDisplay = computed(() => resolvedAcceptValues.value.map((value) => value.slice(1)).join(', '))
  const acceptString = computed(() => toContentTypes(resolvedAcceptValues.value).join(', ') || '*')

  return {
    acceptDisplay, acceptString
  }
}
